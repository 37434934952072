<template>
    <div class="animated fadeIn">
        <CRow>
            <CCol lg="12">
                <CCard>
                    <CCardHeader><font-awesome-icon icon="link" /> New Document Template Prompt
                        <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg" />
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol lg="6" md="6" xl="6">
                                <CInput :lazy="false" label="Name" type="text" placeholder="JO Prompt"
                                    autocomplete="off" v-tocapitalize v-model="template_prompt.name"
                                    invalidFeedback="Must be three(3) characters." 
                                    :value.sync="$v.template_prompt.name.$model"
                                    :isValid="checkIfValid($v.template_prompt, 'name')"
                                    />
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol lg="12" md="12" xl="12">
                                <CTextarea class="grp-text-area" :lazy="false"
                                    label="Description" placeholder="Content..." autocomplete="off" v-tocapitalize 
                                    v-model="template_prompt.description"
                                    :value.sync="$v.template_prompt.description.$model"
                                    :isValid="checkIfValid($v.template_prompt, 'description')"
                                    />
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol lg="12" md="12" xl="12">
                                <CTextarea
                                    :lazy="false"
                                    rows="5"
                                
                                    label="Prompt" placeholder="Content..." autocomplete="off" v-tocapitalize
                                    v-model="template_prompt.prompt"
                                    :value.sync="$v.template_prompt.prompt.$model"
                                    :isValid="checkIfValid($v.template_prompt, 'prompt')"
                                    />
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol col="12" class="text-right">
                                <CButton 
                                    :disabled="!isValid"
                                    shape="pill" 
                                    color="primary"
                                    @click="addTemplatePrompt()"
                                >
                                    <font-awesome-icon icon="save"/> Save
                                </CButton>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>
<script>
import { required, minLength} from "vuelidate/lib/validators"
export default {
    name: 'DocumentTemplatePromptCreate',
    data() {
        return {
            template_prompt: {
                name: '',
                description: '',
                prompt: ''
            },
        }
    },
    created() {
        this.$v.$touch()
        this.$emit('activeTab', 1);
    },
    computed: {
        isValid () { return !this.$v.template_prompt.$invalid },
        isDirty () { return this.$v.template_prompt.$anyDirty },
    },
    validations: { 
        template_prompt: {
            name: { required, minLength: minLength(3) },
            description: { required },
            prompt: { required },

        },
    },
    methods: {
        addTemplatePrompt: function () {
            this.$swal({
                    icon: "warning",
                    title: "Are you sure?",
                    text: "You are trying to create new Document Template Prompt.",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    showLoaderOnConfirm: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    preConfirm: () => { 
                        this.$Progress.start()
                        return axios.post('/drs/document-template-prompt/store', this.template_prompt, {validateStatus: () => true}).then(response => {
                            if ( response.status == 200 ) {
                                this.$swal({
                                    icon: "success",
                                    title: "Success!",
                                    text: `${this.template_prompt.name} has been added.`,
                                })
                                this.template_prompt = [];
                                this.$v.template_prompt.$reset()
                                this.$v.template_prompt.$touch()
                                this.$Progress.finish()
                            }
                        })
                    }
                }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
            return;
        }


    },
    watch: {

    }
}
</script>
